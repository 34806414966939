import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    width: '100%',
    display: "flex",
    border: '1px solid',
    borderColor: '#636F7F',
    borderRadius: '4px',
    overflow: 'hidden'
  },
  tab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: '2px',
    cursor: "pointer",
    transition: "400ms ease-in-out",
    padding: '8px 4px',
    borderColor: '#636F7F',
  },
  activeTab: {
    backgroundColor: '#003E8C'
  }
}));

function Tabs({
  value,
  options = [],
  onChange = () => { },
  ...props
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {
        options.map((option, index) => {
          return (
            <Box
              key={index}
              className={`${classes.tab} ${option._id === value ? classes.activeTab : ''}`}
              onClick={() => onChange(option._id)}
              style={{
                borderRight: index < options.length - 1 ? '1px solid' : 'none',
                width: `${100 / options.length}%`
              }}
              {...props}
            >
              {
                option.icon
                  ? <option.icon style={{ color: option._id === value ? '#fff' : '#000', fontSize: '18px'}}/>
                  : null
              }
              <Typography fontSize={'11px'} color={option._id === value ? '#fff' : '#000'}>
                {option?.label || index}
              </Typography>
            </Box>
          )
        })
      }
    </Box>
  );
}

export default Tabs;