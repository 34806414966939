import Bowser from 'bowser';
import { Levels } from 'components/constants';
import Session from "./Session";

const apiUrl = process.env.REACT_APP_API_URL;

export const subMinutes = function (date, minutes) {
  return new Date(date.getTime() - minutes * 60000);
};

export const getViewportDimensions = () => {

  let width;
  let height;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth !== 'undefined') {
    width = window.innerWidth;
    height = window.innerHeight;
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (typeof document.documentElement !== 'undefined'
    && typeof document.documentElement.clientWidth !=
    'undefined' && document.documentElement.clientWidth !== 0) {
    width = document.documentElement.clientWidth;
    height = document.documentElement.clientHeight;
  }

  // older versions of IE
  else {
    width = document.getElementsByTagName('body')[0].clientWidth;
    height = document.getElementsByTagName('body')[0].clientHeight;
  }
  return { width, height };
};

export const isBrowserSupported = async () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  let isValidBrowser = browser.satisfies({
    windows: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    linux: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>57',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    macos: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>57',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    ios: {
      'internet explorer': '>9999',
      safari: '>=14',
      chrome: '>=79',
      firefox: '>=25',
      opera: '>=36',
      edge: '>=79',
      'samsung internet for android': '>=5'
    },
    android: {
      'internet explorer': '>9999',
      safari: '>=14',
      chrome: '>=47',
      firefox: '>=25',
      opera: '>=36',
      edge: '>=79',
      webview: '>=47',
      'samsung internet for android': '>=5'
    },
    'Chrome OS': {
      'internet explorer': '>9999',
      safari: '>=14',
      chrome: '>=47',
      firefox: '>=25',
      opera: '>=36',
      edge: '>=79',
      'samsung internet for android': '>=5'
    },
  });

  isValidBrowser = isValidBrowser && !(navigator.brave && await navigator.brave.isBrave());

  if (!isValidBrowser) console.error("UNSUPPORTED_BROWSER::", browser);

  if (isValidBrowser) {
    const result = await fetch(
      `${apiUrl}/detect-brave?gclid=3&fbclid=2&sub=marine`
    );
    isValidBrowser = isValidBrowser && (await result.text());
  }

  return isValidBrowser;
};

const getUserMedia = constraints => {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      var getUserMedia = navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(
          new Error('getUserMedia is not implemented in this browser')
        );
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  } else {
    return navigator.mediaDevices.getUserMedia(constraints);
  }
};

export const checkMicrophone = () => {
  return new Promise((resolve, reject) => {
    getUserMedia({ audio: true })
      .then(function (stream) {
        resolve(true);
      })
      .catch(function (err) {
        resolve(false);
      });
  });
};

export const round = (number = 0) => {
  const _number = isNaN(number) ? 0 : number;

  return Math.round(_number);
}

export const secondsToMMSS = (seconds) => {
  if (seconds < 0) return "";
  var MM = Math.floor(seconds / 60);
  var SS = seconds - (MM * 60);

  if (MM < 10) MM = `0${MM}`;
  if (SS < 10) SS = `0${SS}`;

  return `${MM}:${SS}`;
}

export const getClientPrefix = () => {
  const clientName = Session?.client
    ? Session?.client?.name
    : Session.getUser()?.name
  return clientName ? clientName
    .split(" ")
    .map(part => part.toLowerCase())
    .join("-") : clientName;
}

export function capitalizeFirstLetter(input) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function openFullscreen() {
  if (document.body.requestFullscreen) {
    document.body.requestFullscreen();
  } else if (document.body.webkitRequestFullscreen) { /* Safari */
    document.body.webkitRequestFullscreen();
  } else if (document.body.msRequestFullscreen) { /* IE11 */
    document.body.msRequestFullscreen();
  }
}

export const getLevelByScore = (score) => {
  if (score >= 75) return Levels.EXPERT;
  if (score >= 50) return Levels.PROFICIENT;
  if (score >= 25) return Levels.INTERMEDIATE;
  return Levels.BEGINNER;
};

export const detectMobileDevice = () => {
  const deviceList = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  if (deviceList.test(navigator?.userAgent) || navigator?.userAgentData?.mobile) {
    return true;
  }
  return false;
}

export const copyToClipboard = (text, textRef) => {
  if (navigator.clipboard?.writeText) {
    navigator?.clipboard?.writeText(text)
      .then(() => { })
      .catch(() => { });
  } else {
    if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(textRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

export const openSystemSoundSettings = () => {
  if (navigator.userAgent.includes('Macintosh')) {
    window.location.href = 'x-apple.systempreferences:com.apple.preference.sound';
  }else{
    window.open('ms-settings:sound', '_self');
  }
};

export const openSystemPrivacySettings = () => {
  if (navigator.userAgent.includes('Macintosh')) {
    window.location.href = 'x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone';
  }else{
    window.open('ms-settings:privacy-microphone', '_self');
  }
};

export const calculateStringMatchPercentage = (transcribedText, referenceText) => {
  if (!transcribedText || !referenceText) {
    console.error("Both transcribedText and referenceText are required.");
    return 0;
  }

  const normalize = (str) =>
    str
      .toLowerCase()
      .replace(/[^\w\s]/g, "")
      .split(/\s+/);

  const transcribedWords = normalize(transcribedText);
  const referenceWords = normalize(referenceText);

  const commonWords = transcribedWords.filter(word =>
    referenceWords.includes(word)
  );

  const matchPercentage = (commonWords.length / referenceWords.length) * 100;

  return matchPercentage.toFixed(2);
};
export function getColorForSpeechRate(rate) {
  if (rate <= 90) return "#D32F2F";
  if (rate <= 120) return "#E0A000";
  if (rate <= 150) return "#388E3C";
  if (rate <= 170) return "#E0A000";
  return "#D32F2F";
}

export function getColorForCommonWordUsage(percentage) {
  if (percentage <= 50) return "#E0A000";
  if (percentage <= 70) return "#388E3C";
  return "#E0A000";
}

export function getColorForRareWordUsage(percentage) {
  if (percentage > 20) return "#388E3C";
  if (percentage > 5) return "#E0A000";
  return "#E0A000";
}

export function getColorForUniqueWordUsage(percentage) {
  if (percentage <= 40) return "#D32F2F";
  if (percentage <= 60) return "#E0A000";
  if (percentage <= 100) return "#388E3C";
  return "#D32F2F";
}

export * from "./audio.js";
export * from "./cryptoUtil.js";
export * from "./permissions.js";
export * from "./roles.js";
export * from "./Session.js";
export * from "./canvas";
export * from "./OTPAuth";