import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinearProgress from '@mui/material/LinearProgress';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';

import CenterFlexBox from "components/CenterFlexBox";
import CustomButton from 'components/CustomButton';

import useNavigateWithClient from "hooks/useNavigateWithClient";

import { askPermission, checkPermission } from 'utils/permissions';
import Session from 'utils/Session';
import useStore from 'store';
import { QuestionTypeLabels, Sections } from 'components/constants';
import { Button, Chip } from '@mui/material';
import CircularProgressCountDown from 'components/CircularProgressCountDown';
import AppShell from 'components/AppShell';
import { detectMobileDevice, openSystemSoundSettings, subscribeToAudioLevel } from 'utils';
import TroubleShoot from './TroubleShoot';
import { MicCalibration } from 'components/MicCalibration';

const useStyles = makeStyles((theme) => ({
    listItem: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "17px",
        color: "#535353",
        marginBottom: 8,
    },
    container: {
        width: 'fit-content',
        maxWidth: 350,
        backgroundColor: "#FFFFFF",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: 'hidden',
    },
    containerContent: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    title: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
    },
    subTitle: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "15px",
    },
    countdownContainer: {
      backgroundColor: '#fff',
      maxWidth: '350px',
      minWidth: '300px',
      height: '400px',
      overflow: 'hidden',
      borderRadius: '16px'
    },
    section: {
        display: 'flex',
        flexDirection: "column",
        backgroundColor: '#E0E0E0',
        border: '1px solid #E0E0E0',
        borderRadius: '5px',
    },
    description: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        color: '#535353',
        fontWeight: 500,
        fontSize: '11px',
    },
    sectionTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
    },
}));

export function Section({ number, questionCount, questionType, description }) {
    const classes = useStyles();

    return (
        <>
          <Box className={classes.section} gap="2px" my="4px" p={1}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Typography className={classes.sectionTitle}>
                      Section {number} : {QuestionTypeLabels[questionType.toLowerCase()]} ({questionCount} {questionCount === 1 ? "Question" : "Questions"})
                  </Typography>
              </Box>
              <Typography className={classes.description}>
                  {description}
              </Typography>
          </Box>
        </>
    );
}

export default function PreAssessment() {
    const classes = useStyles();
    const navigate = useNavigateWithClient();
    const [sections, setSections] = React.useState({});
    const totalTime = useStore(state => state.totalTime);
    const questions = useStore(state => state.questions);
    const [startCountdown, setStartCountdown] = React.useState(false);

    React.useEffect(() => {
        const tempSections = Sections;
        questions.forEach((question) => {
            switch (question.type) {
                case 'spoken':
                    tempSections.spoken.questionCount += 1;
                    break;
                case 'written':
                    tempSections.written.questionCount += 1;
                    break;
                case 'mcq':
                case 'scq':
                    tempSections.mcq.questionCount += 1;
                    break;
                default: break;
            }
        });
        setSections(tempSections);
    }, []);

    const handleBeginAssessment = () => {
      navigate("/assessment", { replace: true });
    }

    const user = Session.getUser();

    return (
      <AppShell>
      {
        !startCountdown ?
        <CenterFlexBox sx={{ height: "100vh", backgroundColor: "#202124" }} >
          <Box className={classes.container}>
            <Box className={classes.containerContent} p={3}>
                <Typography className={classes.title} mb={1}>
                    Hi, {user?.name?.split(" ")?.[0] || "User"} ! Before we start,
                </Typography>
                <Box
                    background='rgba(255, 255, 255, 0.2)'
                    border='1px solid #E0E0E0'
                    borderRadius='5px'
                    p={1}
                    width="-webkit-fill-available"
                >
                    <Typography className={classes.subTitle}>
                        General Instructions:
                    </Typography>
                    <ol style={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        margin: 0,
                        fontSize: '12px',
                        lineHeight: '18px',
                        paddingInlineStart: 24,
                    }}>
                        <li>Do not refresh the page or press the back button once you start the assessment as this may cause you to lose your progress.</li>
                        <li>Distribute your time appropriately among the questions.</li>
                        <li>There will be no re-tries once you submit an answer.</li>
                    </ol>
                </Box>

                <CenterFlexBox my={2} justifyContent={'flex-start'}>
                    <Typography className={classes.subTitle} mr={1} >
                        Total Duration
                    </Typography>
                    <Chip
                        size="small"
                        icon={<AccessTimeIcon color="primary" />}
                        label={`${Math.round(totalTime / 60)} min`}
                        variant="outlined"
                        color="primary"
                    />
                </CenterFlexBox>
                {
                  Object.entries(sections)
                    .filter(([_, { questionCount }]) => questionCount > 0)
                    .map(([key, { label, questionCount, description }], index) => (
                      <Section
                        key={key}
                        number={index + 1}
                        questionType={key}
                        questionCount={questionCount}
                        description={description}
                      />
                    ))
                }
            </Box>
            {
              sections.spoken?.questionCount > 0 ?
                <MicCalibration next={()=>{setStartCountdown(true)}} />
                : 
                <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px'}}>
                  <CustomButton
                      size="small"
                      onClick={()=>{setStartCountdown(true)}}
                  >
                      Proceed
                  </CustomButton>
                </Box>
            }
          </Box>
        </CenterFlexBox>
        :
        <Box className={classes.countdownContainer}>
          <CircularProgressCountDown
            onDialogClose={() => setStartCountdown(false)}
            proceedToTest={() => handleBeginAssessment()}
          />
        </Box>
      }
      </AppShell>
    );
}
