import discordIcon from "assets/discord_icon.svg";
import apli from 'assets/images/apli.jpeg';
import careerboat from 'assets/images/careerboat.jpeg';
import cipherschool from 'assets/images/cipherschool.jpeg';
import fastjobs from 'assets/images/fastjobs.jpeg';
import foreignadmits from 'assets/images/foreignadmits.jpeg';
import certificate from "assets/images/freebies-certificate.png";
import courses from "assets/images/freebies-courses.png";
import network from "assets/images/freebies-network.png";
import report from "assets/images/freebies-report.png";
import session from "assets/images/freebies-session.png";
import simulation from "assets/images/freebies-simulation.png";
import kudoswall from 'assets/images/kudoswall.jpeg';
import skilledge from 'assets/images/skilledge.jpeg';
import squadiq from 'assets/images/squadiq.jpeg';
import upgradcampus from 'assets/images/upgradcampus.jpeg';
import Check from "@mui/icons-material/CheckCircle";

export const RETAKES_AVAILABLE = 1;

export const AssessmentStates = {
    LOADING: "LOADING",
    FETCHING_QUESTIONS: "FETCHING_QUESTIONS",
    STARTING: "STARTING",
    RUNNING: "RUNNING",
    WAITING_FOR_RESULT: "WAITING_FOR_RESULT",
    COMPLETED: "COMPLETED",
};

export const TutorialTips = [
    {
        id: "first-tip",
        message:
            " Click on the Start button once you're ready to answer. Remember, you're on clock.",
    },
    {
        id: "second-tip",
        message:
            "All the questions have a time allotted to them. Practice finishing your answers in time.",
    },
    {
        id: "third-tip",
        message:
            "Click on Stop once you're done answering. Submit your answer if you're satisfied.",
    },
    {
        id: "fourth-tip",
        message:
            "If you're not satisfied, you can re-record your answers once per question.",
    },
    {
        id: "fifth-tip",
        message:
            " You may skip questions if you wish.",
    },
];

export const QuestionStates = {
    WAITING: "WAITING",
    RECORDING: "RECORDING",
    SUBMITTED: "SUBMITTED",
    ANALYSING: "ANALYSING",
    SHOWING_TUTORIAL: "SHOWING_TUTORIAL",
    RETAKE: "RETAKE",
    DELAY: "DELAY",
    ANALYSED: "ANALYSED",
    ERROR: "ERROR",
    SECTION_DIALOG: "SECTION_DIALOG",
    QUESTION_DIALOG: "QUESTION_DIALOG",
};

export const QuestionTypes = {
    spoken: "spoken",
    written: "written",
    scq: "scq",
    mcq: "mcq",
};

export const QuestionTypeLabels = {
    spoken: "Speaking",
    written: "Writing",
    scq: "SCQ",
    mcq: "MCQ",
};

export const Levels = {
    EXPERT: {
        range: '0-24%',
        label: 'Expert',
        color: '#41D7A1',
        comment: 'Great Work!',
        backgroundColor: '#EDFFF7',
        overallComment: 'You are doing great ✨',
        img: '/src/assets/images/good-emoji.svg',
        cefrLevel: 'CEFR Level C1',
        description: "Speak clearly and use intonation to support meaning so that speech is generally easy to understand and follow; any minor lapses do not obscure meaning. Speak with relative ease on a range of general and academic topics, demonstrating control of an appropriate range of grammatical structures and vocabulary; any minor errors may be noticeable, but do not obscure meaning. "
    },
    PROFICIENT: {
        range: '25-49%',
        label: 'Proficient',
        color: '#367CFF',
        comment: 'Good Work!',
        backgroundColor: '#EDFFF7',
        overallComment: 'You are doing good ✨',
        img: '/src/assets/images/good-emoji.svg',
        cefrLevel: 'CEFR Level B2',
        description: "Speak clearly and without hesitancy on general or familiar topics, with overall good intelligibility; pauses and hesitations (to recall or plan information) are sometimes noticeable when more demanding content is produced, and any mispronunciations or intonation errors only occasionally cause problems for the listener. Produce stretches of speech that demonstrate control of some complex structures and a range of vocabulary, although occasional lapses in precision and accuracy may obscure meaning at times."
    },
    INTERMEDIATE: {
        range: '50-74%',
        label: 'Intermediate',
        color: '#FFCC00',
        comment: 'On Track!',
        backgroundColor: '#FFF9ED',
        overallComment: 'You will be there soon',
        img: '/src/assets/images/avg-emoji.svg',
        cefrLevel: 'CEFR Level B1',
        description: "Speak clearly with minor hesitancies about general or familiar topics; longer pauses are noticeable when speaking about more complex or academic topics, and mispronunciations may obscure meaning at times. Produce short stretches of speech consisting of basic grammatical structures connected with “and”, “because” and “so”; attempts at longer utterances requiring more complex grammatical structures may be marked by errors and pauses for grammatical planning or repair; use vocabulary that is sufficient to discuss general or familiar topics, but limitations in range of vocabulary sometimes result in vague or unclear expression of ideas. "
    },
    BEGINNER: {
        range: '75-100%',
        label: 'Beginner',
        color: '#EE2D2D',
        comment: 'Needs Work!',
        backgroundColor: '#FFF0F0',
        overallComment: 'Lets do some hard work',
        img: '/src/assets/images/bad-emoji.svg',
        cefrLevel: 'CEFR Level A1',
        description: "Speak slowly and carefully so that they make themselves understood, but pronunciation may be strongly influenced by the speaker’s first language and at times be unintelligible; speech may be marked by frequent pauses, reformulations, and false starts. Produce mostly short utterances, connecting phrases with simple linking words (such as “and”) to make themselves understood; grammar and vocabulary are limited, and frequent pauses may occur while searching for words. "
    },
};

export const Sections = {
    [QuestionTypes.spoken]: {
        label: 'Verbal',
        questionCount: 0,
        number: 'I',
        description: "Ensure that you’re in a noise-free environment with a good microphone for best results.",
        instructions: ["Press the microphone icon to start answering",
            "Maximum time limit is set with each answer",
            "Give the answers in English language only"
        ],
    },
    [QuestionTypes.written]: {
        label: 'Writing',
        questionCount: 0,
        number: 'II',
        description: 'Focus on clear, concise, and well-structured writing for the best evaluation',
        instructions: ["Write sufficiently long answers for best results",
            "Maximum word limit : 200 words"],
    },
    [QuestionTypes.mcq]: {
        label: 'Multiple Choice',
        questionCount: 0,
        number: 'III',
        description: 'Multiple correct option questions',
        instructions: ["Select the correct option(s) & submit your answer",
            "No retries once you submit your answer"]
    },
    [QuestionTypes.scq]: {
        label: 'Single Choice',
        questionCount: 0,
        number: 'IV',
        description: 'Single correct option questions',
        instructions: ["Select the correct option & submit your answer",
            "No retries once you submit your answer"]
    }
};

export const Partners = [
    {
        imagePath: apli,
        url: 'https://apli.ai/',
        name: 'Apli.ai'
    },
    {
        imagePath: careerboat,
        url: 'https://careerboat.io/',
        name: 'Careerboat'
    },
    {
        imagePath: cipherschool,
        url: 'https://www.cipherschools.com/',
        name: 'CipherSchool'
    },
    {
        imagePath: fastjobs,
        url: 'https://www.fastjobs.io/',
        name: 'Fastjobs'
    },
    {
        imagePath: upgradcampus,
        url: 'https://upgradcampus.com/',
        name: 'UpGradCampus'
    },
    {
        imagePath: foreignadmits,
        url: 'https://www.foreignadmits.com/',
        name: 'ForeignAdmits'
    },
    {
        imagePath: kudoswall,
        url: 'https://www.kudoswall.com/',
        name: 'Kudoswall'
    },
    {
        imagePath: skilledge,
        url: 'https://skill-edge.com/',
        name: 'Skilledge'
    },
    {
        imagePath: squadiq,
        url: 'https://d6c7y6yhvhiuv.cloudfront.net/',
        name: 'SquardIQ'
    },
]

export const Freebies = [
    {
        name: 'Certificate',
        imagePath: certificate,
        details: 'Certificate to showcase </br> in resume',
        heading: "Boost Your Career with Our Free Communication Skills Certification",
        subheading: <>
            Show off your communication skills in your resume & to recruiters.
            <br /><br />
            Take the 20 min certification and get feedback report & 1-on-1 improvement plan call from our teachers.
        </>,
        buttonColor: "blue",
        buttonText: "Get Certified Today!",
        to: "",
    },
    {
        name: 'Report',
        imagePath: report,
        details: 'Talk to an expert, get closer to your aspirations',
        heading: <>
            Upskill Yourself with&nbsp;
            <span style={{ color: "#49B6FF" }}>industry in-demand courses</span>
        </>,
        subheading: <>
            Our teachers will reach out to you to guide you for your aspirations.
            <br /><br />
            Be it Foreign education, getting a job or internship, or upskilling- we have experts of every domain.
        </>,
        buttonColor: "purple",
        buttonText: "Connect me with an expert!",
        to: "",
        message: <>
            <Check color="success" />
            <span style={{ color: "white", marginLeft: 8, textTransform: "uppercase" }}>
                Success! A counselor will reach out to you on phone call
            </span>
        </>
    },
    {
        name: 'Session',
        imagePath: session,
        details: 'Network with like-minded </br> peer community',
        heading: <>
            Get Your Dream Job/Internship with our 100+ recruitment partners
        </>,
        subheading: <>
            Your profile qualifies for the some of the job roles with our
            100+ partners. We will be sharing your resume & profile with them,
            if selected they will reach out on a phone call.
            <br /><br />
            PS. Give the communication skills test so we can share the complete
            profile & resume with the recruiters.
        </>,
        buttonColor: "orange",
        buttonText: "Connect with Top Recruiters Now.",
        to: "",
    },
    {
        name: 'Feedback',
        imagePath: simulation,
        details: '1:1 counseling session </br> from industry experts',
        heading: <>
            Can you pass your dream job interview?
        </>,
        subheading: <>
            InPrep- AI simulates over 100+ interview experiences.
            <br /><br />
            Test your skills, get feedback on communication skills & core concepts
            with improvement plan & ace your dream job interview!
        </>,
        buttonColor: "grey",
        buttonText: "Get Your Free Subscription Now!",
        to: process.env.REACT_APP_INPREP_BETA_URL || "https://rb.gy/qoinyt",
    },
    {
        name: 'Courses',
        imagePath: courses,
        details: 'Communication skills report </br> with score & feedback',
        heading: <>Can you pass the IELTS/TOEFL test?</>,
        subheading: <>
            Test your qualification or readiness with Languify IELTS TOEFL mock test.
            <br /><br />
            Unlimited 30 min diagnostic or 3 hr comprehensive test or sectional test,
            we have everything you need to excel.
            <br /><br />
            With mock score, feedback report for all 4 sections and a learning plan to improve!
        </>,
        buttonColor: "green",
        buttonText: "Check my IELTS/TOEFL score!",
        to: process.env.REACT_APP_MOCK_URL || "https://rb.gy/6izrva",
    },
    {
        name: 'Network',
        imagePath: network,
        details: '100+ job interview-AI </br> simulation & feedback',
        heading: <>
            Find people who are going towards the same goal!
        </>,
        subheading: <>
            Join community of like-minded peers. Connect with others, share your experiences.
            <br /><br />
            Get curated resources, updates, and support on your journey.
        </>,
        buttonColor: "yellow",
        buttonText: "Join Discord community!",
        buttonIcon: <img src={discordIcon} alt="Discord_Icon" height={18} />,
        to: process.env.REACT_APP_DISCORD_URL || "https://discord.gg/RfFQpkNdrx",
    }
];

export const AspirationsFreebiesMapping = {
    0: [1, 4, 5],
    1: [1, 3, 5],
    2: [0, 1, 2, 3, 5],
    3: [0, 1, 2, 3, 5],
};


export const troubleshootSuggestions = [
  'Sit in a quiet place',
  'Check that your external microphone is properly connected.',
  'Stay close to the microphone for best results.'
];

export const troubleshootTranscriptError = [
  'Low-quality or faulty microphones may not capture clear audio. Use good quality microphone.',
  'Being too far or too close to the microphone can affect input quality. Maintain an optimal distance from the mic.',
  'Ambient sounds (e.g., fans, traffic, conversations) can interfere with audio clarity.',
  'Try to avoid mumbled, fast, or unclear speech.',
  'Other running applications may interfere with the mic input, close them & try again.',
];

export const troubleshootMicError = [
  'Check that your external microphone is properly connected & in appropriate proximity.',
  'Don’t use faulty or damaged microphone ports.',
  'Verify that the correct microphone is selected.',
  'The microphone itself may be damaged or non-functional.',
  'Another application might be using or blocking the microphone.',
  'Some antivirus programs may block microphone access for security reasons.',
  'Corporate system sometimes block access for security reasons.',
]