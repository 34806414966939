import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PrivateRoute from 'components/PrivateRoute';
import SessionHelper from "components/SessionHelper";
import Home from "./Home";
import PreAssessment from "./PreAssessment";
import Tutorial from "./Tutorial";
import UnsupportedBrowserError from "./UnsupportedBrowser";
import Workspace from "./Workspace";

import theme from "theme";

import { LoadingProvider, SnackbarProvider } from "contexts";
import Login from './Login';
import ProctoringProvider from 'contexts/ProctoringProvider';

const Assessment = React.lazy(() => import("./Assessment"));
const Certificate = React.lazy(() => import('./Certificate'));
const Congratulations = React.lazy(() => import('./Congratulations'));
const LinkExpired = React.lazy(() => import('./LinkExpired'));
const MobileDevice = React.lazy(() => import('./MobileDevice'));
const NotFound = React.lazy(() => import('./NotFound'));
const Report = React.lazy(() => import('./Report'));
const ResumeSignup = React.lazy(() => import('./ResumeSignup'));

function NavigateToLanguify() {
    window.location.href = process.env.REACT_APP_LANGUIFY_URL;
}

export default function Screens() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <SessionHelper>
                    <LoadingProvider>
                        <SnackbarProvider>
                            <ProctoringProvider>
                                <React.Suspense fallback={"Loading..."}>
                                    <Routes>
                                        <Route path="/workspace" element={<Workspace />} />

                                        <Route path="/signup" element={<ResumeSignup />} />
                                        <Route path="/get-freebies" element={<ResumeSignup />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/certificate/:id" element={<Certificate />} />
                                        <Route path="/congratulations" element={
                                            <PrivateRoute component={Congratulations} />
                                        } />
                                        <Route path="/report" element={<Report />} />
                                        <Route
                                            path="/mobile-device"
                                            element={<MobileDevice />}
                                        />
                                        <Route path="/r/:referralCode" element={<NavigateToLanguify />} />
                                        <Route path="/" element={<NavigateToLanguify />} />

                                        <Route path=":client">
                                            <Route
                                                path="pre-assessment"
                                                element={<PreAssessment />}
                                            />
                                            <Route
                                                path="tutorial"
                                                element={<Tutorial />}
                                            />
                                            <Route
                                                path="assessment"
                                                element={<Assessment />}
                                            />
                                            <Route
                                                path="unsupported-browser"
                                                element={<UnsupportedBrowserError />}
                                            />
                                            <Route path="link-expired"
                                                element={<LinkExpired />}
                                            />
                                            <Route path="error" element={<NotFound />} />
                                            <Route path="" element={<Home />} />
                                        </Route>
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </React.Suspense>
                            </ProctoringProvider>
                        </SnackbarProvider>
                    </LoadingProvider>
                </SessionHelper>
            </ThemeProvider>
        </BrowserRouter>
    );
}