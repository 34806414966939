import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  chipRoot: {
    borderRadius: '10px',
    padding: '4px 8px',
    backgroundColor: '',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center', 
    gap: '4px',
    zIndex:2
  },
  disabledRootChip: {
    backgroundColor: `gray !important`,
    color: `gray !important`
  }
}
));

const Chip = ({ 
  content, color, bgColor, startIcon = null, endIcon = null, sx = {}, disabled,
  ...props 
}) => {
  const classes = useStyles();

  return (
    <Box 
      className={`${classes.chipRoot} ${disabled ? classes.disabledRootChip : ''}`} 
      style={{ backgroundColor: bgColor, color: color, ...sx }} 
      {...props}
    >
      {
        startIcon && startIcon
      }
      <Typography fontSize='12px'>
        {content}
      </Typography>
      {
        endIcon && endIcon
      }
    </Box>
  )
}

export default Chip;
