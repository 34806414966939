import Client from "./_client";
import { detectMobileDevice, Session } from "utils";

export const finishAttempt = async (attemptId, data={}) => {
  if (!attemptId) return;

  const result = await new Client({
    path: `/sepAttempt/${attemptId}/finish`,
    payload: { finishedAt: Date.now(), ...data },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! finish the attempt. Please try again!"
    );
  }
  return result?.data;
};

export const startAttempt = async (attemptId) => {
  if (!attemptId) return;

  const result = await new Client({
    path: `/sepAttempt/${attemptId}/start`,
    payload: { startedAt: Date.now(), mobile: detectMobileDevice() },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! start the attempt. Please try again!"
    );
  }
  return result?.data;
};

export const getAttemptById = async (attemptId) => {
  const result = await new Client({
    path: `/sepAttempt/${attemptId}`,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get attempt. Please try again!"
    );
  }

  return result?.data;
}

export const getAttemptByToken = async (token) => {
  const url = `/sepAttempt/token?at=${token}`;

  try {
    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
      throw new Error(
        result.message || "Uh Oh! Attempt fetch failed. Please try again!"
      );
    }
    
    return result?.data;
  } catch (error) {
    throw new Error(
      error.message || "Uh Oh! Attempt fetch failed. Please try again!"
    );
  }
};

export const getAttemptStatus = async (attemptId) => {
  if (!attemptId) return;

  const result = await new Client({
    path: `/sepAttempt/${attemptId}/status`,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to check attempt status. Please try again!"
    );
  }
  return result?.data;
};

export const createAttempt = async () => {
  const result = await new Client({
    path: "/sepAttempt",
    payload: {
      user: Session.getUser()._id,
      sepTemplate: process.env.REACT_APP_SEP_RESUME_TEMPLATE,
      mobile: false,
      client: null,
      startedAt: null,
      userRole: 'SEP_RESUME',
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable to create the attempt. Please try again!"
    );
  }
  return result?.data;
};
